import React, { useState, createContext, useContext } from "react"

export const LanguageContext = createContext({})

export const LanguageContextProvider = ({ children }: props) => {
    const [language, setLanguage] = useState("pl")

    return (
        <LanguageContext.Provider value={{ language, setLanguage }}>
            {children}
        </LanguageContext.Provider>
    )
}

export const useLanguage: any = () => useContext(LanguageContext)

interface props {
    children: React.ReactNode
}
